table{
    margin: 40px 0;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}
th{
    background: var(--black);
    color: var(--grayMedium);
}
td, th{
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
tr:nth-child(even) {
    background-color: #dddddd;
}
td.celulaCor{
    min-width: 100px;
}
li{
    list-style: none;
}
ul{
    padding-inline-start: 0px;
}
td.btnUD{
    text-align: center;
}