* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

:root {
  --primary: #81f595;
  --secondary: #51ef6b;
  --secondaryDarker: #33bf4a;
  --black: #242423;
  --blue: #A8E4EF;
  --blackLighter: #9E9E9E;
  --grayLight: #F5F5F5;
  --grayMedium: #e5e5e5; 
  --white: #FFFFFF;
  --frontEnd: #6BD1FF;
  --backEnd: #00C86F;
}

html,
body {
  margin: 0;
  padding: 0;
}

h1{
  color: #fafafa;
}

a {
  color: inherit;
} 

#root{
  min-height: calc(100vh - var(--bodyPaddingTop));
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar { 
  background-color: #141414; 
  width: 8px;
}
::-webkit-scrollbar-thumb { 
  background-color: var(--frontEnd); 
}